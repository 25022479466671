import image_01 from './../img/social/202503/01.jpg'
import image_02 from './../img/social/202503/02.jpg'
import image_03 from './../img/social/202503/03.jpg'
import image_04 from './../img/social/202503/04.jpg'


export const slides202503 = [
    {
      src: image_01,
      title: '',
      description: '',
    },
    {
      src: image_02,
      title: '',
      description: '',
    },
    {
      src: image_03,
      title: '',
      description: '',
    },
    {
      src: image_04,
      title: '',
      description: '',
    }
  ]